import axios from "axios"
import jtd from "jtd"
import {Message} from 'element-ui'
const store = require('store')

const requests = axios.create({
	baseURL: CONFIG.axios.baseURL,
	timeout: 20000,
	validateStatus: function (status) {
		return status >= 200 && status <= 500 // default
	}
})
requests.interceptors.request.use(function (config) {
	config.headers["Access-Token"] = localStorage.getItem("Access-Token") || ''
	// config.headers["ENTERPRISE"] = JSON.parse(localStorage.getItem("enterprise")).id || null
	config.headers["ENTERPRISE"] =store.get('user/enterprise')?store.get('user/enterprise').id:null
	return config
})

requests.interceptors.response.use(function (response) {
	const schema = response.config.schema
	if(response.status != 200){
		
		if(response.status == 403){
			Message.closeAll();
			Message.error({message: "没有权限请联系系统管理员"});
			localStorage.clear()
			setTimeout(() => {	
				if(window.location.pathname==='/personalcenter/mainpage'){
					var querys = getQueryVariable()
					window.sessionStorage.setItem("inviteData",JSON.stringify(querys))
				}
				location.href = '/login/passlogin'
			}, 500);
		}else{
			Message.error({message: "网络异常请联系系统管理员"});
		}
	}else{
		if(response.data.code != 200){
			Message.closeAll()
			if(response.data.message=="项目id数据错误"){
				Message({
					type: 'warning',
					duration: 2000,
					message: "您还没有项目，请先去企业管理新建项目",
				})
			}else{
				Message({
					type: 'error',
					duration: 2000,
					message: response.data.message,
				})
			}
		}
	}
	if (schema) {
		if (!jtd.isSchema(schema) || !jtd.isValidSchema(schema)) {
			// debugger
			throw Error("Schema not valid")
		}
		const errors = jtd.validate(schema, response.data)
		if (errors.length) {
			// debugger
			throw Error("Data not valid")
		}
	}

	return response
})

//获取路由参数
function getQueryVariable() {
	let href = window.location.href
	let query = href.substring(href.indexOf('?')+1);
	let vars = query.split("&");
	let obj = {}
	for (var i = 0; i < vars.length; i++) {
		let pair = vars[i].split("=");
		obj[pair[0]] = pair[1]
	}
	return obj;
}

export default requests
