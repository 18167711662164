require("core-js/proposals/relative-indexing-method");
require("reset-css");
require("/main.sass");

import 'babel-polyfill';

import "./components/public";

import Vue from "vue";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import SlideVerify from 'vue-monoplasty-slide-verify';
import directives from './mixin/directive'
import Vue2OrgTree from 'vue2-org-tree'
Vue.use(directives)
Vue.use(SlideVerify);

Vue.use(ElementUI);
Vue.use(Vue2OrgTree)
if (process.env.NODE_ENV === "development") Vue.config.devtools = true

import VueCompositionAPI from "@vue/composition-api";
Vue.use(VueCompositionAPI);

import VueRouter from "vue-router";
Vue.use(VueRouter);

import { createPinia, PiniaVuePlugin, setMapStoreSuffix } from "pinia";
setMapStoreSuffix("");
Vue.use(PiniaVuePlugin);

import constant from "/constant";

import { Loading, Message } from 'element-ui'
Vue.use(Loading.directive)
Vue.prototype.$message = Message;
var echarts = require("echarts");
Vue.prototype.$echarts = echarts;
Vue.prototype.EventBus = new Vue();

const router = new VueRouter(require("/router"))
router.beforeEach(require("/router").beforeEnter)

const VueRouterPush = VueRouter.prototype.push//路由重复push捕捉错误
VueRouter.prototype.push = function push(to) {
	return VueRouterPush.call(this, to).catch(err => err)
}
const VueRouterReplace = VueRouter.prototype.replace//路由重复replace捕捉错误
VueRouter.prototype.replace = function replace(to) {
	return VueRouterReplace.call(this, to).catch(err => err)
}
new Vue({
	el: "#root",
	beforeCreate() {
		Vue.prototype.$bus = this
	},
	router,
	pinia: createPinia(),
	provide() {
		return {
			// database: require("/pinia/database").default(),
			constant,
		};
	},
	data() {
		return { now: undefined }
	},
	created() {
		window.setInterval(() => this.now = +new Date(), 1000)
	},
});


