<template>
   <div class="score_box">
      <div class="title_box">
         <h5>评分</h5>
         <i class="iconfont" @click="closeScore">&#xe6c5;</i>
      </div>
      <p>{{showType}}</p>
      <div class="con_box">
         <div class="item_box">
            <p>{{perText}}：{{person[0] ? person[0] : '　'}}</p>
            <div class="line" v-for="(item,index) in attributeArr" :key="index">
               <span>{{item.rankValue}}</span>
               <el-rate
                  v-model="item.value"
                  :icon-classes="iconClasses"
                  void-icon-class="iconfont icon-guanzhu-weiguanzhu"
                  void-color="#333333"
                  :colors="['#FF9900', '#FF9900', '#FF9900']">
               </el-rate>
            </div>
         </div>
         <div class="item_box" v-if="type == 'pass'">
            <p>负责人：{{person[1] ? person[1] : '　'}}</p>
            <div class="line" v-for="(item,index) in attributePassArr" :key="index">
               <span>{{item.rankValue}}</span>
               <el-rate
                  v-model="item.value"
                  :icon-classes="iconClasses"
                  void-icon-class="iconfont icon-guanzhu-weiguanzhu"
                  void-color="#333333"
                  :colors="['#FF9900', '#FF9900', '#FF9900']">
               </el-rate>
            </div>
         </div>
      </div>
      <div class="btn_box">
         <el-button type="DKBackground" size="mini" @click="submit">保存</el-button>
      </div>
   </div>
</template>

<script>
import { setRecord,getTaskRank } from "/api/kpiSetting";
const store = require('store');
export default {
   name: "scorePage",
   props: [
      "type",//评分的类型 -- 任务通过、任务交付、阶段交付、项目完成
      "title",//任务名及任务id、阶段名及阶段id、项目名及项目id
      "person",//人员名称 -- [0]title中第一个人名[1]title中第二个人名
      "ScoreMaskInfo",//被评分人的信息
      "ScoreMaskInfo2",//被评分人的信息----任务通过时
   ],
   data(){
      return {
         iconClasses: ['iconfont icon-guanzhu-weiguanzhu', 'iconfont icon-guanzhu-weiguanzhu', 'iconfont icon-guanzhu-weiguanzhu'],
         perText: null,
         attributeArr: [],//评分属性arr
         attributePassArr: [],//评分属性arr---任务通过时
         enterpriseId: null,
      }
   },
   computed: {
      showType(){
         if(this.type == "pass"){
            this.perText = '执行人';
            return `请对【${this.title?.name || ''}】的执行人【${this.person[0] ? this.person[0] : ''}】与该任务的负责人【${this.person[1] ? this.person[1] : ''}】评分`;
         }else if(this.type == "task"){
            this.perText = '执行人';
            return `请对【${this.title?.name || ''}】的执行人【${this.person[0] ? this.person[0] : ''}】评分`;
         }else if(this.type == "stage"){
            this.perText = '阶段负责人';
            return `请对【${this.title?.name || ''}】的阶段负责人【${this.person[0] ? this.person[0] : ''}】评分`;
         }else if(this.type == "project"){
            this.perText = '项目负责人';
            return `请对【${this.title?.name || ''}】的项目负责人【${this.person[0] ? this.person[0] : ''}】评分`;
         }
      }
   },
   mounted(){
      if(this.ScoreMaskInfo.enterpriseId){
         this.enterpriseId = this.ScoreMaskInfo.enterpriseId;
      }else if(this.ScoreMaskInfo.fzEnterpriseId){
         this.enterpriseId = this.ScoreMaskInfo.fzEnterpriseId;
      }else if(this.ScoreMaskInfo.executeEnterpriseId){
         this.enterpriseId = this.ScoreMaskInfo.executeEnterpriseId;
      }
      getTaskRank(this.enterpriseId).then((res)=>{
         if(res.data.code == 200){
            var data = res.data.result ? res.data.result : [];
            data.map((value)=>{
               value.value = 0;
            })
            this.attributeArr = data;
            if(this.type == "pass"){
               this.attributePassArr = JSON.parse(JSON.stringify(data));
            }
         }
      })
   },
   methods: {
      //点击关闭
      closeScore(){
         this.$emit("closeScore");
         if(this.type != 'task'){
            location.reload();
         }
      },
      //点击保存
      submit(){
         if(this.type == "pass"){
            var scores = this.attributeArr.map((value)=>{
               return value.value
            })
            var scores2 = this.attributePassArr.map((value)=>{
               return value.value
            })
            if(this.$route.name == 'projectview'){
               setRecord(
                  [
                     {
                        deptId: this.ScoreMaskInfo.executeDepartmentId,
                        empId: this.ScoreMaskInfo.executeEmployeeId,
                        enterpriseId: this.ScoreMaskInfo.executeEnterpriseId,
                        scoreType: 1,
                        scoreUserId: store.get("user").id,
                        scoreUserType: 2,
                        scores,
                        taskId: this.title.id,
                        userId: this.ScoreMaskInfo.executeUserId,
                        userType: 1,
                     },
                     {
                        deptId: this.ScoreMaskInfo.stageFzrDepartmentId,
                        empId: this.ScoreMaskInfo.stageFzrEmployeeId,
                        enterpriseId: this.ScoreMaskInfo.stageFzrEnterpriseId,
                        scoreType: 1,
                        scoreUserId: store.get("user").id,
                        scoreUserType: 2,
                        scores: scores2,
                        taskId: this.title.id,
                        userId: this.ScoreMaskInfo.stageFzrUserId,
                        userType: 2,
                     }
                  ]
               ).then((res)=>{
                  if(res.data.code == 200){
                     this.$message({
                        message: '保存成功',
                        type: 'success'
                     });
                     this.closeScore();
                     location.reload();
                  }
               })
            }else{
               setRecord(
                  [
                     {
                        deptId: this.ScoreMaskInfo.departmentId,
                        empId: this.ScoreMaskInfo.employeeId,
                        enterpriseId: this.ScoreMaskInfo.enterpriseId,
                        scoreType: 1,
                        scoreUserId: store.get("user").id,
                        scoreUserType: 1,
                        scores,
                        taskId: this.title.id,
                        userId: this.ScoreMaskInfo.userId,
                        userType: 1,
                     },
                     {
                        deptId: this.ScoreMaskInfo2.departmentId,
                        empId: this.ScoreMaskInfo2.employeeId,
                        enterpriseId: this.ScoreMaskInfo2.enterpriseId,
                        scoreType: 1,
                        scoreUserId: store.get("user").id,
                        scoreUserType: 1,
                        scores: scores2,
                        taskId: this.title.id,
                        userId: this.ScoreMaskInfo2.userId,
                        userType: 2,
                     }
                  ]
               ).then((res)=>{
                  if(res.data.code == 200){
                     this.$message({
                        message: '保存成功',
                        type: 'success'
                     });
                     this.closeScore();
                     this.$router.go(0);
                  }
               })
            }
         }else if(this.type == "task"){
            var scores = this.attributeArr.map((value)=>{
               return value.value
            })
            setRecord(
               [
                  {
                     deptId: this.ScoreMaskInfo.departmentId,
                     empId: this.ScoreMaskInfo.employeeId,
                     enterpriseId: this.ScoreMaskInfo.enterpriseId,
                     scoreType: 2,
                     scoreUserId: store.get("user").id,
                     scoreUserType: 3,
                     scores,
                     taskId: this.title.id,
                     userId: this.ScoreMaskInfo.userId,
                     userType: 1,
                  }
               ]
            ).then((res)=>{
               if(res.data.code == 200){
                  this.$message({
                     message: '保存成功',
                     type: 'success'
                  });
                  this.closeScore();
               }
            })
         }else if(this.type == "stage"){
            var scores = this.attributeArr.map((value)=>{
               return value.value
            })
            setRecord(
               [
                  {
                     deptId: this.ScoreMaskInfo.fzDepartmentId,
                     empId: this.ScoreMaskInfo.employeeId,
                     enterpriseId: this.ScoreMaskInfo.fzEnterpriseId,
                     scoreType: 3,
                     scoreUserId: store.get("user").id,
                     scoreUserType: 4,
                     scores,
                     stageId: this.title.id,
                     userId: this.ScoreMaskInfo.fzUserId,
                     userType: 2,
                  }
               ]
            ).then((res)=>{
               if(res.data.code == 200){
                  this.$message({
                     message: '保存成功',
                     type: 'success'
                  });
                  this.closeScore();
                  location.reload();
               }
            })
         }else if(this.type == "project"){
            var scores = this.attributeArr.map((value)=>{
               return value.value
            })
            setRecord(
               [
                  {
                     deptId: this.ScoreMaskInfo.departmentId,
                     empId: this.ScoreMaskInfo.employeeId,
                     enterpriseId: this.ScoreMaskInfo.enterpriseId,
                     projectId: this.title.id,
                     scoreType: 4,
                     scoreUserId: store.get("user").id,
                     scoreUserType: 5,
                     scores,
                     userId: this.ScoreMaskInfo.fzUserId,
                     userType: 2,
                  }
               ]
            ).then((res)=>{
               if(res.data.code == 200){
                  this.$message({
                     message: '保存成功',
                     type: 'success'
                  });
                  this.closeScore();
                  location.reload();
               }
            })
         }
      }
   }
}
</script>

<style lang="sass" scoped>
$FontFamily: 'PingFangSC-Regular', 'PingFang SC', sans-serif
@import '/style/utils.sass'
.score_box
   position: fixed
   left: 50%
   top: 50%
   z-index: 100
   transform: translate(-50%,-50%)
   box-sizing: border-box
   padding: 20px
   background-color: #fff
   box-shadow: $box-shadow
   .title_box
      position: relative
      margin-bottom: 24px
      >h5
         font: normal 400 18px/26px $FontFamily
         color: $input-header
      >i
         position: absolute
         right: 1px
         top: 7px
         cursor: pointer
         font-size: 10px
   >p
      margin-bottom: 12px
      font: normal 400 14px/22px $FontFamily
      color: $input-header
   .con_box
      display: flex
      margin-bottom: 20px
      .item_box
         width: 190px
         >p
            margin-bottom: 10px
            font: normal 400 14px/22px $FontFamily
            color: $input-header
         .line
            display: flex
            margin-bottom: 10px
            >span
               min-width: 42px
               max-width: 70px
               white-space: normal
               overflow: hidden
               text-overflow: ellipsis
               margin-right: 10px
               font: normal 400 14px/22px $FontFamily
               color: $input-header
            .el-rate
               margin: 1px 0
      .item_box:first-child
         margin-right: 15px
   .btn_box
      display: flex
      justify-content: center
      align-items: center
      .el-button
         width: 160px
         height: 34px
         font: normal 400 12px/12px $FontFamily
</style>