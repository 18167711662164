import Vue from 'vue'
/*
  按钮防抖动指令
*/
const directive = Vue.directive('debounce', {
    inserted(el, binding) {
        el.addEventListener('click', () => {
            if (!el.disabled) {
                el.disabled = true
                setTimeout(() => {
                    el.disabled = false
                }, 2000)
            }
        })
    }
})
export default directive
