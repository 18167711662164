import requests from "../request"

//获取阶段权重
export const getProjectLevel=(enterpriseId)=>{
    return requests.get("/enterprise-manage/project/level/"+enterpriseId)
}

//设置阶段权重
export const setProjectLevel=(data)=>{
    return requests.post("/enterprise-manage/project/level",data)
}

//获取任务难易系数
export const getTaskLevel=(enterpriseId)=>{
    return requests.get("/enterprise-manage/task/weight/"+enterpriseId)
}

//设置任务难易系数
export const setTaskLevel=(data)=>{
    return requests.post("/enterprise-manage/task/weight",data)
}

//获取任务评分项
export const getTaskRank=(enterpriseId)=>{
    return requests.get("/enterprise-manage/task/rank/"+enterpriseId)
}

//设置任务评分项
export const setTaskRank=(data)=>{
    return requests.post("/enterprise-manage/task/rank",data)
}

//获取分数档次
export const getScoreLevel=(enterpriseId)=>{
    return requests.get("/enterprise-manage/score/level/"+enterpriseId)
}

//设置分数档次
export const setScoreLevel=(data)=>{
    return requests.post("/enterprise-manage/score/level",data)
}

//保存绩效压力参数
export const savePerformancePressureArg=(data)=>{
    return requests.post("/enterprise-manage/performancePressureArg/save",data)
}

//查询绩效压力参数
export const getPerformancePressureArg=(data)=>{
    return requests.post("/enterprise-manage/performancePressureArg/search",data)
}

//保存绩效爆灯参数
export const savePerformanceBoomArg=(data)=>{
    return requests.post("/enterprise-manage/performanceBoomArg/save",data)
}

//查询绩效爆灯参数
export const getPerformanceBoomArg=(data)=>{
    return requests.post("/enterprise-manage/performanceBoomArg/search",data)
}

//查询绩效方案设置
export const performanceSchemeSearch=(data)=>{
    return requests.post("/enterprise-manage/performanceScheme/search",data)
}

//保存绩效方案设置
export const performanceSchemeSave=(data)=>{
    return requests.post("/enterprise-manage/performanceScheme/save",data)
}
//获取任务评分
export const getRecord = (data)=>{
    return requests.post("/enterprise-manage/task/rank/record/data",data);
 }
 
//设置任务评分
 export const setRecord = (data)=>{
    return requests.post("/enterprise-manage/task/rank/record",data);
 }
