const NEW_VERSION_LESS_THEN_CURRENT_VERSION = {
	message: '新版本不能小于等于现有版本',
	user: '新版本不能小于等于现有版本',
}

const DUPLICATE = {
	message: '目标文件夹存在相同类型且同名的文件',
	user: '目标文件夹存在同名文件',
}

const UKOWN_ERROR = {
	message: 'ukown_error',
	user: '未知错误',
}

export default {
	NEW_VERSION_LESS_THEN_CURRENT_VERSION,
	DUPLICATE,
	UKOWN_ERROR,
}