<template>
   <span :id="id" class="overflow-tooltip" :title="title">{{content}}</span>
</template>

<script>
export default {
   name: "overflowTooltip",
   props: [
      "content"
   ],
   data(){
      return {
         title: '',
         id: Math.random().toString(36).slice(2)
      }
   },
   mounted(){
      const el = document.getElementById(this.id);
      const elComputed = document.defaultView.getComputedStyle(el,'');
      const padding = parseInt(elComputed.paddingLeft.replace('px','')) + parseInt(elComputed.paddingRight.replace('px',''));
      const range = document.createRange();
      range.setStart(el,0);
      range.setEnd(el,el.childNodes.length);
      const rangeWidth = range.getBoundingClientRect().width;
      if(rangeWidth + padding > el.offsetWidth || el.scrollWidth > el.offsetWidth){
         this.title = this.content;
      }
   }
}
</script>

<style lang="sass" scoped>
.overflow-tooltip
   display: inline-block
   width: 100%
   white-space: nowrap
   overflow: hidden
   text-overflow: ellipsis
   vertical-align: bottom
</style>