import { Message } from 'element-ui'
const store = require('store')
module.exports = {
	mode: 'history',
	routes: [
		{
			path: '/',
			redirect: '/login/passlogin'
		},
		// 资料中心 文件管理
		{
			path: "/documents/index",
			component: () => import("/pages"),
			children: [
				{
					name: "documents",
					path: "",
					component: () =>
						import(
							/* webpackChunkName: "pages/documents" */
							"/pages/documentManage/projectKunPan"
						),
				},
			],
		},
		// 资料中心 回收站
		// {
		// 	path: "/documents/recycle-bin",
		// 	component: () => import("/pages"),
		// 	children: [
		// 		{
		// 			name: "documents/recycle-bin",
		// 			path: "",
		// 			component: () =>
		// 				import(
		// 					/* webpackChunkName: "pages/documents/recycle-bin" */
		// 					"/pages/documents/recycle-bin"
		// 				),
		// 		},
		// 	],
		// },
		// // 资料中心 极速概览
		// {
		// 	path: "/documents/overview",
		// 	component: () => import("/pages"),
		// 	children: [
		// 		{
		// 			name: "documents/overview",
		// 			path: "",
		// 			component: () =>
		// 				import(
		// 					/* webpackChunkName: "pages/documents/overview" */
		// 					"/pages/documents/overview"
		// 				),
		// 		},
		// 	],
		// },
		// 资料中心智能下载
		// {
		// 	path: "/documents/smart-download",
		// 	component: () => import("/pages"),
		// 	children: [
		// 		{
		// 			name: "documents/smart-download",
		// 			path: "",
		// 			component: () =>
		// 				import(
		// 					/* webpackChunkName: "documents/smart-download" */
		// 					"/pages/documents/smart-download"
		// 				),
		// 		},
		// 	],
		// },
		// 通讯录
		{
			path: '/communication/address-book',
			component: () => import("/pages"),
			children: [
				{
					name: "communication/address-book",
					path: "",
					component: () =>
						import(
							/* webpackChunkName: "communication/address-book" */
							"/pages/communication/address-book"
						),
				},
			],
		},
		// // 登录注册
		// {
		// 	name: "loginregister",
		// 	path: "/loginregister",
		// 	component: () => import("/pages/LoginRegister"),
		// 	children: [
		// 		{
		// 			name: "companyregister",
		// 			path: "companyregister",
		// 			component: () => import("/pages/LoginRegister/CompanyRegister"),
		// 			meta: {
		// 				messageType: "regist",
		// 			},
		// 		},
		// 		{
		// 			name: "profilereg",
		// 			path: "profilereg",
		// 			component: () =>
		// 				import("/pages/LoginRegister/CompanyRegister/ProfileReg"),
		// 			meta: {
		// 				difFooter: true,
		// 			},
		// 			beforeEnter(to, from, next) {
		// 				if (from.name === "companyregister") next();
		// 				else {
		// 					Message.warning("请您先注册或登录")
		// 					next({ name: "login" });
		// 				}
		// 			},
		// 		},
		// 		{
		// 			name: "successreg",
		// 			path: "successreg",
		// 			component: () =>
		// 				import("/pages/LoginRegister/CompanyRegister/SuccessReg"),
		// 			beforeEnter(to, from, next) {
		// 				if (from.name === "profilereg") next();
		// 				else {
		// 					Message.warning("请您先注册或登录")
		// 					next({ name: "login" });
		// 				}
		// 			},
		// 		},
		// 		{
		// 			name: "staffregister",
		// 			path: "staffregister",
		// 			component: () => import("/pages/LoginRegister/StaffRegister"),
		// 			meta: {
		// 				messageType: "regist",
		// 			},
		// 		},
		// 		{
		// 			name: "login",
		// 			path: "login",
		// 			component: () => import("/pages/LoginRegister/Login"),
		// 			meta: {
		// 				loginMethod: "password",
		// 			},
		// 		},
		// 		{
		// 			name: "messagelogin",
		// 			path: "messagelogin",
		// 			component: () => import("/pages/LoginRegister/Login"),
		// 			meta: {
		// 				loginMethod: "message",
		// 				messageType: "login",
		// 			},
		// 		},
		// 		{
		// 			name: "changepassword",
		// 			path: "changepassword",
		// 			component: () => import("/pages/LoginRegister/Login"),
		// 			meta: {
		// 				loginMethod: "changePassword",
		// 				messageType: "changePassword",
		// 			},
		// 		},
		// 	],
		// },
		//注册登录
		{
			name: "login",
			path: "/login",
			component: () => import("/pages/Login"),
			children: [
				{
					name: "establish",
					path: "establish",
					meta: {
						title: "创建/加入企业"
					},
					component: () => import("/pages/Login/Establish"),
					beforeEnter(to, from, next) {
						if (localStorage.getItem("Access-Token")){
							next()
						}else {
							Message.warning("请您先注册或登录")
							next('/login/passlogin');
						}
					},
				},
				{
					name: "join",
					path: "join",
					meta: {
						title: "企业注册"
					},
					component: () => import("/pages/Login/Join"),
					beforeEnter(to, from, next) {
						if (localStorage.getItem("Access-Token")){
							if(from.name == 'success'){
								Message("企业注册成功，我们会在1~3个工作日内审核完成，请耐心等待")
							} 
							next();
						}
						else {
							Message.warning("请您先注册或登录")
							next('/login/passlogin');
						}
					},
				},
				{
					name: "supplement",
					path: "supplement",
					meta: {
						title: "企业注册"
					},
					component: () => import("/pages/Login/Supplement"),
					beforeEnter(to, from, next) {
						if (localStorage.getItem("Access-Token")) next();
						else {
							Message.warning("请您先注册或登录")
							next('/login/passlogin');
						}
					},
				},
				{
					name: "success",
					path: "success",
					meta: {
						title: "企业注册"
					},
					component: () => import("/pages/Login/Success"),
					beforeEnter(to, from, next) {
						if (localStorage.getItem("Access-Token")) next();
						else {
							Message.warning("请您先注册或登录")
							next('/login/passlogin');
						}
					},
				},
				{
					name: "passlogin",
					path: "passlogin",
					meta: {
						title: "账号登录"
					},
					component: () => import("/pages/Login/PassLogin"),
				},
				{
					name: "codelogin",
					path: "codelogin",
					meta: {
						title: "账号登录"
					},
					component: () => import("/pages/Login/CodeLogin"),
				},
				{
					name: "forgetpass",
					path: "forgetpass",
					meta: {
						title: "重置密码"
					},
					component: () => import("/pages/Login/ForgetPass"),
				},
				{
					name: "register",
					path: "register",
					meta: {
						title: "账号注册"
					},
					component: () => import("/pages/Login/Register"),
				},
				{
					path: '',
					redirect: 'passlogin'
				}
			]
		},
		//商城
		{
			name: "shop",
			path: "/shop",
			component: () => import("/pages/Shop"),
			children: [
				
			]
		},
		//甘特图
		{
			name: "gannt",
			path: "/gannt",
			component: () => import("/pages/Gannt"),
			children: [
				
			]
		},
		// 企业管理
		{
			name: "enterprisemanagement",
			path: "/enterprisemanagement",
			component: () => import("/pages"),
			children: [
				// 部门
				{
					name: "department",
					path: "department",
					component: () => import("/pages/EnterpriseManagement/Department"),
				},
				// 角色
				{
					name: "roleplay",
					path: "roleplay",
					component: () => import("/pages/EnterpriseManagement/Roleplay"),
				},
				// 项目
				{
					name: "projectspanel",
					path: "projectspanel",
					component: () => import("/pages/EnterpriseManagement/ProjectsPanel"),
				},
				// 职位管理
				{
					name: "postmanage",
					path: "postmanage",
					component: () => import("/pages/EnterpriseManagement/PostManage"),
				},
				// 合同清单
				{
					name: "ContractManage",
					path: "contractmanage",
					component: () => import("/pages/ContractManage"),
				},
				// 待审核合同清单
				{
					name: "ContractManagers",
					path: "contractmanagers",
					component: () => import("/pages/ContractManagers"),
				},
				// 公告管理
				{
					name: "AnnounceRegular",
					path: "announceregular",
					component: () => import("/pages/AnnounceRegular"),
				},
				// 员工管理
				{
					name: "EmployeeRegular",
					path: "employeeregular",
					component: () => import("/pages/EmployeeRegular"),
				},
				//用户组管理
				{
					name: "UserGroup",
					path: "usergroup",
					component: () => import("/pages/EnterpriseManagement/UserGroup"),
				},
				{
					name:"Order",
					path:"order",
					component:()=>import("/pages/EnterpriseManagement/order")
				},
				{
					name:"Allotment",
					path:"allotment",
					component:()=>import("/pages/EnterpriseManagement/Allotment")
				},
				//模板清单
				{
					name:"TemplateList",
					path:"templateList",
					component:()=>import("/pages/EnterpriseManagement/TemplateList")
				}
			],
		},
		// {
		// 	name: "InviteRegister",
		// 	path: "/inviteregister/:mobilePhone/:inviatationSymbol",
		// 	component: () => import("/pages/EmployeeRegular/InviteRegister"),
		// },
		// 项目
		{
			name: "project",
			path: "/project",
			component: () => import("/pages"),
			children: [
				// 甘特图
				{
					name: "projectgantt",
					path: "projectgantt",
					// component: () => import("/pages/ProjectManagement/ProjectGantt"),
					component: () => import("/pages/ProjectManagement/ProjectGantt/project-report.vue"),
				},
				// 项目管理
				{
					name: "projectpersonnel",
					path: "projectpersonnel",
					// component: () => import("/pages/ProjectManagement/ProjectPersonnel"),
					component: () => import("/pages/ProjectManagement/ProjectMember"),
				},
				// 项目阶段
				{
					name: "projectphase",
					path: "projectphase",
					component: () => import("/pages/ProjectManagement/stageManagement"),
				},
				// 项目任务
				{
					name: "projecttasks",
					path: "projecttasks",
					component: () => import("/pages/ProjectManagement/ProjectTasks"),
					children: [
						// 任务列表
						{
							name: "tasklist",
							path: "tasklist",
							component: () =>
								//import("/pages/ProjectManagement/ProjectTasks/TaskList"),
								import("/pages/ProjectManagement/TaskManagement"),
						},
						// 任务详情
						{
							name: "taskdetail",
							path: "detail/:taskId",
							component: () =>
								import("/pages/ProjectManagement/ProjectTasks/TaskDetail"),
						},
					],
				},
				// 项目视图
				{
					name: "projectview",
					path: "projectview",
					component: () => import("/pages/ProjectManagement/ProjectView"),
				},
				// 交付视图
				{
					name: "DeliveryView",
					path: "deliveryview",
					// component: () => import("/pages/DeliveryView"),
					component: () => import("/pages/ProjectManagement/DeliveryView"),
				},
				//项目变更
				// {
				// 	name:"ProjectChange",
				// 	path:"projectChange",
				// 	component:()=>import("/pages/ProjectManagement/ProjectChange")
				// },
				//项目角色
				{
					name:"ProjectRole",
					path:"projectRole",
					component:()=>import("/pages/ProjectManagement/ProjectRole")
				},
				//项目组织
				// {
				// 	name:"ProjectOrganize",
				// 	path:"projectOrganize",
				// 	component:()=>import("/pages/ProjectManagement/ProjectOrganization")
				// },
				//营销看板
				{
					name: 'MarketBoard',
					path: 'marketboard',
					component: () => import('/pages/Cockpit/MarketBoard')
				},
				{
					name: 'projectKunPan',
					path: 'projectKunPan',
					component: () => import("/pages/documentManage")
				},
			],
		},
		// 公告
		{
			name: "AnnounceRegular",
			path: "/announceregular", 
			component: () => import("/pages"),
			children: [
				{
					name: "",
					path: "",
					component: () => import("/pages/AnnounceRegular"),
				}
			]
		},
		{
			name: "ContractManage",
			path: "/contractmanage",
			component: () => import("/pages/ContractManage"),
		},
		{
			name: "ContractManagers",
			path: "/contractmanagers",
			component: () => import("/pages/ContractManagers"),
		},
		// {
		// 	name: "PersonalTopbar",
		// 	path: "/personaltopbar",
		// 	component: () => import("/pages/PersonalCenter/PersonalTopbar"),
		// },
		// 个人中心
		{
			name: "PersonalCenter",
			path: "/personalcenter",
			component: () => import("/pages"),
			children: [
				{
					name: "MainPage",
					path: "mainpage",
					component: () => import("/pages/PersonalCenter/MainPage"),
				},
				// 更改密码
				{
					name: "ChangePassword",
					path: "changepassword",

					component: () => import("/pages/PersonalCenter/ChangePassword"),
				},
				//我的企业
				{
					name: "MyEnterprise",
					path: "myenterprise",
					component: ()=>import("/pages/PersonalCenter/MyEnterprise"),
				}
			],
		},
		// 协议相关
		{
			name: "ServiceAgreement",
			path: "/serviceAgreement",
			component: () => import("/pages/agreement/serviceAgreement"),
		},
		{
			name: "PrivacyAgreement",
			path: "/privacyAgreement",
			component: () => import("/pages/agreement/privacyAgreement"),
		},

		{
			name: "MainPage",
			path: "/mainpage",
			component: () => import("/pages/PersonalCenter/MainPage"),
		},
		{
			name: "ContractManagers",
			path: "/contractmanagers",
			component: () => import("/pages/ContractManagers"),
		},
		// 员工日报
		{
			name: "EmployeeNews",
			path: "/employeenews",
			component: () => import("/pages/DailyNews"),
		},
		// 看日报
		{
			name: "SeeDaily",
			path: "/seedaily",
			component: () => import("/pages/DailyNews/SeeDaily"),
		},
		// 驾驶舱
		{
			name: "Cockpit",
			path: "/cockpit",
			component: () => import("/pages"),
			children: [
				{
					name: "ManyBoard",
					path: "manyboard",
					component: () => import("/pages/Cockpit/ManyBoard"),
				},
				{
					name: "SingleBoard",
					path: "singleboard",
					component: () => import("/pages/Cockpit/SingleBoard"),
				},
				{
					name: "StageBoard",
					path: "stageboard",
					component: () => import("/pages/Cockpit/StageBoard"),
				},
				{
					name: "OutsideBoard",
					path: "outsideboard",
					component: () => import("/pages/Cockpit/OutsideBoard"),
				},

				//人力成本看板
				{
					name: "PerformanceBoard",
					path: "performanceBoard",
					component: () => import("/pages/Cockpit/PerformanceBoard"),
				},
				//驾驶舱甘特图
				{
					name: 'ProjectsBoard',
					path: 'projectsboard',
					component: () => import('/pages/Cockpit/ProjectsBoard')
				},
				{
					path: "",
					redirect: "manyboard"
				}
			]
		},
		// {
		// 	name: "Cockpit",
		// 	path: "/cockpit",
		// 	component: () => import("/pages/Cockpit"),
		// },

		{
			name: "allback",
			path: "/allback",
			component: () => import("/pages/allback"),
		},

		//绩效管理
		{
			name: "kpiManagement",
			path: "/kpiManagement",
			component: () => import("/pages"),
			children: [
				//绩效方案参数设置
				{
					name:"KpiPlanParmSetting",
					path:"kpiParmSetting",
					component:()=>import("/pages/EnterpriseManagement/KpiPlanParmSetting")
				},
				//绩效方案
				{
					name:"KpiProgramme",
					path:"kpiProgramme",
					component:()=>import("/pages/EnterpriseManagement/KpiProgramme")
				}
			]
		},		
		//智慧服务
		{
			name: "SmartServices",
			path: "/smartservices",
			component: () => import("/pages"),
			children: [
				//智慧项目优化
				{
					name:"SmartOptimize",
					path:"smartoptimize",
					component:()=>import("/pages/SmartServices/SmartOptimize")
				},
				//智慧能源管理
				{
					name:"SmartManage",
					path:"smartmanage",
					component:()=>import("/pages/SmartServices/SmartManage")
				},
				//智慧图纸审查
				{
					name:"SmartCensor",
					path:"smartcensor",
					component:()=>import("/pages/SmartServices/SmartCensor")
				},
				//智慧工程造价
				{
					name:"SmartCost",
					path:"smartcost",
					component:()=>import("/pages/SmartServices/SmartCost")
				}
			]
		},	
	],

	beforeEnter: (to, from, next) => {//路由守卫
		// let privilegeList = store.get('user/privilege');
		// if (to.path == "/enterprisemanagement/roleplay") {
		// 	if (privilegeList && fun("PAGE_role", privilegeList)) next()//角色管理页面
		// } else if (to.path == "/enterprisemanagement/department") {
		// 	if (privilegeList && fun("PAGE_department", privilegeList)) {
		// 		next()//部门管理页面
		// 	} else {
		// 		next("/project/projectpersonnel")//
		// 	}
		// } else if (to.path == '/documents/overview') {
		// 	if (privilegeList && fun("PAGE_overview", privilegeList)) next()//急速概览页面
		// } else if (to.path == '/documents/') {
		// 	if (privilegeList && fun("PAGE_datamanagement", privilegeList)) next()//资料管理页面
		// } else if (to.path == "/documents/smart-download") {
		// 	if (privilegeList && fun("PAGE_smartdownload", privilegeList)) next()//智能下载页面
		// } else if (to.path == '/documents/recycle-bin') {
		// 	if (privilegeList && fun("PAGE_returnstation", privilegeList)) next()//回收站页面
		// } else if (to.path == '/cockpit/manyboard') {
		// 	if (privilegeList && fun("PAGE_projectview", privilegeList)) next()//项目看板页面
		// } else if (to.path == '/enterprisemanagement/projectspanel') {
		// 	if (privilegeList && fun("PAGE_projectlist", privilegeList)) next()//项目列表页面
		// } else if (to.path == '/enterprisemanagement/contractmanage') {
		// 	if (privilegeList && fun("PAGE_contractlist", privilegeList)) next()//合同清单页面
		// } else if (to.path == '/enterprisemanagement/postmanage') {
		// 	if (privilegeList && fun("PAGE_position", privilegeList)) next()//职位管理页面
		// } else if (to.path == '/enterprisemanagement/employeeregular') {
		// 	if (privilegeList && fun("PAGE_employee", privilegeList)) next()//员工管理页面
		// } else if (to.path == '/enterprisemanagement/announceregular') {
		// 	if (privilegeList && fun("PAGE_notice", privilegeList)) next()//公告管理页面
		// } else {
		// 	next()
		// }
		next()
	}
};
function fun(privilegeCode, privilegeList) {
	let flag = false
	privilegeList.forEach(item => {
		if (item.privilegeCode === privilegeCode) {
			flag = true
			return
		} else {
			if (item.childPrivilegeList != null) {
				// item.childPrivilegeList.forEach(x=>{
				//     if(x.name===name){
				//         flag=true
				//     }
				// })
				if (fun(privilegeCode, item.childPrivilegeList)) {
					flag = fun(privilegeCode, item.childPrivilegeList)
					return
				}

			}
		}
	});
	return flag
}


